.portableText {
  @apply text-lg leading-relaxed;
}

.portableText p,
.portableText ul,
.portableText ol,
.portableText blockquote {
  @apply my-6;
}

.portableText h2 {
  @apply mb-4 mt-12 text-3xl leading-snug;
}

.portableText h3 {
  @apply mb-4 mt-8 text-2xl leading-snug;
}

.portableText a {
  @apply text-blue-500 underline;
}
.portableText a:hover {
  @apply text-blue-800;
}
