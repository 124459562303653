.song-list-album-artwork {
  width: 50px;
  height: 50px;
}

.song-list-row:hover .play,
.song-list-row.active-song.paused:hover .play {
  display: block;
  opacity: 0.9;
}
.song-list-row.active-song.paused .play {
  display: block;
  opacity: 0.7;
}
.song-list-row.active-song.playing:hover .play,
.song-list-row.active-song.loading:hover .play {
  display: none;
}
.song-list-row.active-song .song-list-index,
.song-list-row:hover .song-list-index {
  opacity: 0;
}
.song-list-row .play {
  display: none;
  position: absolute;
  opacity: 0.5;
}
.song-list-row.active-song.playing .pause {
  display: block;
}
.song-list-row.active-song.playing:hover .pause {
  opacity: 0.9;
}
.song-list-row.active-song.loading .pause {
  display: none;
}
.song-list-row .pause {
  display: none;
  opacity: 0.7;
  height: auto;
}
.song-list-row .pause {
  position: absolute;
  display: none;
}
.song-list-row .spinner {
  position: absolute;
  display: none;
}
.song-list-row .spinner.active {
  display: inline-block;
}
.active-song {
  background-color: #1a1a1a;
  background-color: radial-gradient(crimson, skyblue);
}

.song-list-album-artwork img,
.song-list-album-artwork svg {
  height: auto;
  aspect-ratio: 1 / 1;
}
